const apiDomain =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_API : process.env.ZLOGIN_BACKEND_URL;

export const zPermissionApi =
  process.env.NODE_ENV !== 'production'
    ? process.env.VUE_APP_ZPERMISSION
    : process.env.ZPERMISSION_URL;

export const loginURI = apiDomain;
export const graphQLServiceURI = `${loginURI}/graphql`;

export const zLicenceApi =
  process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_ZLICENCE : process.env.ZLICENCE_URL;
